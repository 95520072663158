import React, { useEffect } from 'react';
import { storage } from '../../libs/localStorage';

function Logout () {
    const {
        clearToken
      } = storage();
    
    useEffect(() => {
        clearToken();
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL as string);
    }, []);
    return (
        <div>
        </div>
    );
};

export default Logout;