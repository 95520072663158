const storeToken = (tokenValue: string) => {
    localStorage.setItem('token', tokenValue);
   
    // setToken(tokenValue);
};

const retrieveToken = () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
        return storedToken
    } else { return null };
};

const clearToken = () => {
    localStorage.removeItem('token');
    // setToken('');
};

export const storage = () => ({
    storeToken,
    retrieveToken,
    clearToken
});