import React, { useRef, useEffect } from "react";
import styles from "./BackgroundVideo.module.css";

interface IBackgroundVideoProps {
  mode: string;
}
const BackgroundVideo: React.FC<IBackgroundVideoProps> = ({ mode }) => {
  const videoRef: any = useRef();

  useEffect(() => {
    videoRef && videoRef.current && videoRef.current.play();
  }, [mode]);

  return (
    <video
      //@ts-ignore
      autoPlay
      muted
      loop
      ref={videoRef}
      className={styles.bgvideo}
    >
      <source type="video/mp4" src="/videos/background.mp4" />
      Your browser does not support HTML5 video.
    </video>
  );
};

export default BackgroundVideo;
