import React, { useState } from "react";
import styles from "./Register.module.css";
import BackgroundVideo from "../BackgroundVideo/BackgroundVideo";

interface IRegisterProps {
  // mode: string;
  // setMode: React.Dispatch<React.SetStateAction<string>>;
}
const Register: React.FC<IRegisterProps> = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [errormessage, setErrorMessage] = useState("");

  let HandleRegister = () => {
   
  };

  return (
    <React.StrictMode>
        <div className={styles.fullwidthcontainer}>
        <BackgroundVideo mode="" />
        <div className={styles.containerblock}>
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <div className={styles.registerbox}>
            <img src="/images/logo.png" className={styles.logo} /><br/>
            {errormessage && (
              <div className={styles.errormessage}>{errormessage}</div>
            )}
            <div className="form-group w-100">
      
              <input
                type="text"
                id="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className={styles.input}
                required={true}
                placeholder="First Name"
              />
            </div>
            <div className="form-group w-100">
            
              <input
                type="text"
                id="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className={styles.input}
                required={true}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group w-100">
             
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                required={true}
                placeholder="Email ID"
              />
            </div>
            <div className="form-group w-100 mb-3">
         
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.input}
                required={true}
                placeholder="Password"
              />
            </div>
            <div className="form-group w-100 mb-3">
             
              <input
                type="password"
                id="confirmpassword"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={styles.input}
                required={true}
                placeholder="Confirm Password"
              />
            </div>
            <div className="form-group w-100">
              <button
                type="submit"
                className={styles.registerbutton}
                onClick={(e) => HandleRegister()}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Register&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          </div>
        </div>
        </div>
    </div>
    </React.StrictMode>
  );
};

export default Register;