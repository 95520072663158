import React, { useEffect, useState, useRef } from "react";
import styles from "./Login.module.css";
import BackgroundVideo from "../BackgroundVideo/BackgroundVideo";
import { storage } from '../../libs/localStorage';
import Loading from '../../components/Loading/Loading';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ILoginProps {
  // mode: string;
  // setMode: React.Dispatch<React.SetStateAction<string>>;
}

const Login: React.FC<ILoginProps> = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { storeToken, retrieveToken } = storage();
  const token = retrieveToken();
  const captchaRef = useRef(null);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_API_BASE_URL + "api/verifytoken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              token: token
            }),
          });

          const data = await response.json();

          if (data?.header?.status == 200) {
            setIsLoading(false);
            data?.data?.usertype == 'A' ? 
            window.location.replace(process.env.REACT_APP_DASHBOARD_URL + '/?token=' + token) :
            window.location.replace(data?.data?.customerpanelurl + '/?token=' + token);
          } else {
            setIsLoading(false);
            toast.error(data?.message || "Invalid credentials", { autoClose: 3000 });
          }
        } catch (error) {
          setIsLoading(false);
          toast.error("Network error, please try again", { autoClose: 3000 });
        }
      }
      fetchData();
    }
    setIsLoading(false);
  }, [token]);

  let handleLogin = async () => {
    if (!username) {
      toast.error("Please enter username", { autoClose: 3000 });
    } else if (!password) {
      toast.error("Please enter password", { autoClose: 3000 });
    } else if (!isCaptchaSuccessful) {
      toast.error("Please re-verify that you're not a robot below", { autoClose: 3000 });
    } else {
      setIsLoading(true);
      try {
        // if (isCaptchaSuccessful === true) {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailid: username,
            password: password,
          }),
        });

        const data = await response.json();

        if (data?.header?.status == 200 && data?.data?.customerpanelurl) {
          setIsLoading(false);
          storeToken(data?.data?.token);
          data?.data?.usertype == 'A' ? 
            window.location.replace(process.env.REACT_APP_DASHBOARD_URL + '/?token=' + data?.data?.token) :
            window.location.replace(data?.data?.customerpanelurl + '/?token=' + data?.data?.token);
          // window.location.replace(data?.data?.customerpanelurl + '/?token=' + data?.data?.token);
        } else {
          setIsLoading(false);
          toast.error(data?.header?.message || "Invalid credentials", { autoClose: 3000 });
        }
      // } else {
      //   // toast.error("Check the I'm not a robot box", { autoClose: 3000 });
        
      // }
      } catch (error) {
        setIsLoading(false);
        toast.error("Network error, please try again", { autoClose: 3000 });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // if (isLoading) return <Loading />

  function onChange(value: any) {
    // console.log("Captcha value:", value);
    value && setIsCaptchaSuccess(true);
  }

  return (
    <React.StrictMode>
      <BackgroundVideo mode={""} />
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>
     
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div  className={styles.Loginmain}>
            <img src="/images/full-logo.svg" className={styles.logo} alt="Logo" />
            <div className={styles.header} style={{ font: "normal normal bold 30px/23px 'Rubik', sans-serif" }}></div>

            <div style={{display:"flex",flexDirection:"column",alignItems:"center" , width:"100%"}}>
            <div style={{width:"100%",marginBottom:"6px"}} >
            <div className={styles.label} style={{ font: "normal normal bold 15px/23px 'Rubik', sans-serif"}}>User name</div>
            <div className={styles.inputWrapper}>
            <input
              type="text"
              id="username"
              placeholder="Type Username here"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              className={styles.input}
              style={{
                font: "normal 15px 'Rubik', sans-serif",
                color: "#1F2E39",
              }}
              required
            />
            </div>
            </div>
            </div>

            <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%" }}>
            <div style={{width:"100%",marginBottom:"6px"}}>
              <div className={styles.label} style={{ font: "normal normal bold 15px/23px 'Rubik', sans-serif" }}>Password</div>
              <div className={styles.inputWrapper}>
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  id="password"
                  placeholder="Type password here"
                  value={password}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setPassword(e.target.value)}
                  className={styles.input}
                  style={{
                    font: "normal 15px 'Rubik', sans-serif",
                    color: "#1F2E39",
                  }}
                  required
                />
                <img
                  src={isPasswordVisible ? "/images/eye-open.svg" : "/images/eye-close.svg"}
                  alt={isPasswordVisible ? 'Hide password' : 'Show password'}
                  onClick={togglePasswordVisibility}
                  className={styles.icon}
                />
              </div>
            </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px",}}>
              <ReCAPTCHA
                // sitekey="6LfQO_MpAAAAAFrtR0NFk45lQvLjEKHK0kzY6FuK"
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                //  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
                size="normal"
              />
            </div>
            {isLoading && <div id="loader-container" style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <img style={{ width: "120px" }}
          src="/images/Loader.gif" />
      </div>}
            <button className={styles.btnlogin} style={{ font: "normal 700 15px/22.5px 'Rubik', sans-serif" }} onClick={handleLogin}>
              Login
              <img className={styles.btnloginicon} src="/images/arrow.png" alt="Arrow" />
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.StrictMode>
  );
};

export default Login;
